import React from "react";
import ErrorPage from "./ErrorPage";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error | any;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };

  componentDidCatch = (error: Error) => {
    this.setState({ error: error });
  };

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorPage message={error?.message} /> : children;
  }
}

export { ErrorBoundary };
