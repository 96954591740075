import { DeployedService } from "features";
import { SeatIdService } from "./SeatIdService";
import Box from "@mui/material/Box";

export function SeatIdControl({ services }: { services?: DeployedService[] | undefined }) {
  if (!services) {
    return <Box>Seat ID service is creating!</Box>;
  }

  const seatIdService = services.find((service: DeployedService) => service.name === "seatid");

  if (!seatIdService) {
    return <Box>Seat ID service not available yet!</Box>;
  }

  return <SeatIdService url={seatIdService.url} />;
}
