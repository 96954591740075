import React, { ReactElement } from "react";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormStateReturn,
} from "react-hook-form";

export function Field<T extends FieldValues>({
  control,
  name,
  rules,
  renderInput,
}: {
  control: Control<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"> | undefined;
  renderInput: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<T, Path<T>>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<T>;
  }) => ReactElement;
}) {
  return <Controller rules={rules} control={control} name={name} render={renderInput} />;
}
