import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { PlusIcon, LinkTab } from "components";
import { Router } from "constant/router";
import { useGetAllInstanceQuery, useGetAllAcmInstanceQuery } from "features";
import { HomeContainer } from "theme/elements";

export function Home() {
  const navigate = useNavigate();

  const { data: instanceList = [] } = useGetAllInstanceQuery();
  const { data: acmInstanceList = [] } = useGetAllAcmInstanceQuery();

  const onNewInstance = () => {
    navigate(Router.instance().create());
  };

  return (
    <HomeContainer data-testid="homepage">
      <Box sx={{ pb: 2 }}>
        <Typography paragraph variant="h1" data-testid="page-title">
          Overview
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2} mb={2}>
          <Stack direction="row" spacing={2}>
            <LinkTab
              to={Router.overview().instances()}
              label={`App Instances (${instanceList.length})`}
              data-testid="app-instance"
            />
            <LinkTab
              to={Router.overview().acmInstances()}
              label={`Virtual Aircrafts (${acmInstanceList.length})`}
              data-testid="app-aircraft"
            />
          </Stack>
          <CustomButton variant="outlined" onClick={onNewInstance} startIcon={<PlusIcon />}>
            Add app instance
          </CustomButton>
        </Stack>
        <Outlet />
      </Box>
    </HomeContainer>
  );
}
