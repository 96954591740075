declare global {
  interface Window {
    _env_: {
      REACT_APP_API_BASE_URL?: string;
      REACT_APP_KEYCLOAK_URL?: string;
      REACT_APP_KEYCLOAK_REALM?: string;
      REACT_APP_KEYCLOAK_CLIENT_ID?: string;
      REACT_APP_USER_MANAGEMENT_WEB_API_URL?: string;
      REACT_APP_ENV_LABEL?: string;
      REACT_APP_VPN_API_BASE_URL?: string;
      REACT_APP_SOCKET_BASE_URL?: string;
    };
  }
}

export const IS_PROD: boolean = process.env.NODE_ENV !== "development";

export const DISABLE_DEBUG_LOGGING: boolean = process.env.REACT_APP_DISABLE_DEBUG_LOGGING === "true" || IS_PROD;

export const DATABASE_PASSWORD_REQUIREMENTS = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/);

export const ENV_NAME = process.env.REACT_APP_ENV_NAME;

const API_BASE_URL: string =
  (window._env_ ? window._env_.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL) || "";

const API_SOCKET_URL: string = process.env.NODE_ENV === "test" ? "" : process.env.REACT_APP_SOCKET_BASE_URL || "";

const VPN_API_BASE_URL: string =
  (window._env_ ? window._env_.REACT_APP_VPN_API_BASE_URL : process.env.REACT_APP_VPN_API_BASE_URL) || "";

const KEYCLOAK_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_URL
  : process.env.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_REALM: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_REALM
  : process.env.REACT_APP_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_CLIENT_ID
  : process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const USER_MANAGEMENT_WEB_API_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_USER_MANAGEMENT_WEB_API_URL
  : process.env.REACT_APP_USER_MANAGEMENT_WEB_API_URL;
const ENV_LABEL: string | undefined = window._env_ ? window._env_.REACT_APP_ENV_LABEL : process.env.REACT_APP_ENV_LABEL;

const DEV_PORTAL_LINK: string = "https://developerportal.int.ifesw.com";

enum INSTANCE_STATUS {
  READY = "Ready",
  CREATING = "Creating",
  PREPARING = "Preparing",
  WARNING = "Warning",
  ERROR = "Error",
}

export {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  API_BASE_URL,
  USER_MANAGEMENT_WEB_API_URL,
  ENV_LABEL,
  VPN_API_BASE_URL,
  DEV_PORTAL_LINK,
  API_SOCKET_URL,
  INSTANCE_STATUS,
};
