import { createApi, fetchBaseQuery, FetchArgs, FetchBaseQueryError, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { getToken } from "@control-tower/aerq-navigation-library";
import { KeycloakInstance } from "keycloak-js";
import { API_BASE_URL } from "constant";
import { hideLoadingOverlay, showLoadingOverlay } from "utils/loader";

let apiAuthToken: any = undefined;
let keycloakInt: KeycloakInstance;

export const setAuthToken = async (keycloak: KeycloakInstance) => {
  const token = await getToken(keycloak);
  apiAuthToken = token.Authorization;
  keycloakInt = keycloak;
};

export const getAccessToken = () => {
  return apiAuthToken;
};

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: async (headers) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = await getToken(keycloakInt);
    if (token?.Authorization) {
      headers.set("Authorization", token.Authorization);
    }
    return headers;
  },
});

const baseQueryWithNProgress: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  try {
    showLoadingOverlay();
    const result = await baseQuery(args, api, extraOptions);
    hideLoadingOverlay();
    return result;
  } catch (err) {
    hideLoadingOverlay();
    throw err;
  }
};

const baseQueryWithRetry = baseQueryWithNProgress;

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "vtr-dashboard",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ["Instances", "AcmInstances", "Fapi", "Catalog", "Settings", "SeatId"],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
  /**
   * refetchOnMountOrArgChange used to encourage re-fetching in additional situations where the default behavior would instead serve cached data.
   */
  // refetchOnMountOrArgChange: 30,
});
