import { Credentials } from "types";

export enum ServiceTypes {
  DATABASE = "DATABASE",
  DEVELOPMENT = "DEVELOPMENT",
  COMMUNICATION = "COMMUNICATION",
  AIRBORNE = "AIRBORNE",
  GROUND = "GROUND",
  OTHERS = "OTHERS",
  ACM = "ACM",
}

interface CommonServiceType {
  name: string;
  visible: boolean;
  type: ServiceTypes;
  version: string;
  protocol: string;
  url: string;
}

export interface DeployedService extends CommonServiceType {
  packages?: CommonServiceType[];
}

export interface DeployedAcmService {
  name: string;
  visible: boolean;
  packages?: CommonServiceType[];
}

export interface AvailableService {
  name: string;
  serviceName: string;
  type: ServiceTypes;
  versions: string[];
}

export interface InstanceStatusType {
  name: string;
  status: string;
  lastTransitionTime: string;
}

export interface InstanceType {
  id: string;
  k8sname: string;
  name: string;
  services: DeployedService[];
  createdAt: string;
  ownerid: string;
  rootPassword?: string;
  database?: Credentials;
  status?: InstanceStatusType[] | null;
  type?: string;
}

export interface AcmInstanceType {
  id: string;
  k8sname: string;
  name: string;
  services: DeployedAcmService[];
  createdAt: string;
  ownerid: string;
  rootPassword?: string;
  database?: Credentials;
  status?: InstanceStatusType[] | null;
  type?: string;
}

export interface CreateInstanceType {
  name: string;
  sshPub: string;
  details?: string;
  database: Credentials;
  rootPassword?: string;
  services: { name: string; version: string }[];
}
