import { styled } from "@mui/material/styles";
import Paper, { PaperProps } from "@mui/material/Paper";
import { Box, BoxProps, MenuItem, MenuItemProps, Tab, TabProps } from "@mui/material";

export const ContentHeaderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const Card = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
  border: `1px solid #E0E0E0`,
  boxShadow: "none",
  minHeight: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardHeader = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .title-section": {
    "& > a": {
      textDecoration: "none",
      fontSize: "12px",
    },
  },
}));

export const CardBody = styled(Box)<BoxProps>(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
export const CardFooter = styled(Box)<BoxProps>(() => ({
  textAlign: "right",
}));

export const Loader = styled(Box)<BoxProps>(() => ({
  display: "flex",
  minHeight: "300px",
  justifyContent: "center",
  alignItems: "center",
}));

export const FormWrapper = styled(Box)<BoxProps>(() => ({
  maxWidth: "720px",
}));

export const EmptyBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2em",
  color: theme.palette.grey[500],
  minHeight: "200px",
}));

export const ErrorContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: theme.palette.grey[200],
}));

export const ErrorContent = styled(Box)<BoxProps>(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const ErrorIcon = styled("span")(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(2),
  "& svg": {
    height: "50px",
    width: "50px",
    "& path": {
      stroke: "#ca0029",
    },
  },
}));

export const HomeContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  mb: -4,
  justifyContent: "space-between",
}));

export const PageContainer = styled(Box)<BoxProps>(() => ({}));

export const CustomTab = styled(Tab)<TabProps>(({ theme }) => {
  return {
    fontSize: "20px",
    // color: "#757575",
    flexDirection: "row",
    minHeight: "48px",
    "&.Mui-selected": {
      marginBottom: 0,
      background: `linear-gradient(135.8deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 98.64%)`,
      backgroundSize: "100% 3px",
      backgroundPosition: "bottom 0 left 0,bottom 5px left 0",
      backgroundRepeat: "no-repeat",
      color: "#000000",
    },
  };
});

export const DeleteMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  color: "#DF3F41",
  "& svg": {
    width: "18px",
    stroke: "#DF3F41",
  },
}));
