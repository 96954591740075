import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Search as SearchIcon } from "react-feather";

const useClasses = makeStyles((theme: Theme) => ({
  marginDense: {
    margin: 0,
  },
  adornedStart: {},
  adornment: {
    color: theme.palette.grey[500],
  },
  input: {
    color: theme.palette.grey[900],
    paddingTop: 10.5,
    paddingBottom: 10.5,
    lineHeight: "19px",
    height: "auto",
    "&::placeholder": {
      color: theme.palette.grey[500],
      opacity: 1,
    },
  },
  notchedOutline: {
    top: -4,
  },
}));

export type Option = { label: string; id: string; type?: string };

export function Search({
  options = [],
  placeholder,
  onChange,
}: {
  options?: Option[];
  placeholder?: string;
  onChange: (option: Option | null) => void;
}) {
  const classes = useClasses();
  return (
    <Autocomplete
      options={options}
      data-testid="search-autocomplete"
      onChange={(_, option) => {
        onChange(option);
      }}
      renderInput={({ InputProps: { ref }, inputProps, ...params }) => (
        <FormControl variant="outlined" margin="dense" classes={{ marginDense: classes.marginDense }}>
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.adornment}>
                  <SearchIcon width={14} height={14} />
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
                notchedOutline: classes.notchedOutline,
              },
              ref,
            }}
            inputProps={{
              ["data-testid"]: "search-input",
              ...inputProps,
            }}
          />
        </FormControl>
      )}
    />
  );
}
