import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CopyToClipboard } from "components";
import { CopyIcon } from "components/icons";
import { SshType } from "features/instances";
import React from "react";
import { Trash as TrashIcon } from "react-feather";

export function SshSettingsComponent({
  sshList = [],
  onUpdateSsh,
}: {
  sshList: SshType[];
  onUpdateSsh: (items: SshType[]) => void;
}): JSX.Element {
  const onDelete = (index: number) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const _sshList: SshType[] = sshList.filter((_, i: number) => i !== index);
    onUpdateSsh(_sshList);
  };

  return (
    <Box data-testid="ssh-settings" sx={{ border: "1px solid #BDBDBD", borderRadius: "4px" }}>
      {sshList.length > 0 ? (
        sshList.map((sshKey, key) => {
          return (
            <Box
              key={key + sshKey.name}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
                pb: 0,
                "&:last-child": { pb: 3 },
              }}
            >
              <Typography variant="caption" fontSize={"16px"}>
                {sshKey.name}
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ "& svg": { width: "18px", paddingTop: "8px" }, display: "inline-block", cursor: "pointer" }}>
                  <CopyToClipboard value={sshKey.pubKey}>
                    {(copy, text) => (
                      <Tooltip title={text === "Copied!" ? text : sshKey.pubKey}>
                        <span onClick={copy} data-testid="copyIcon">
                          <CopyIcon />
                        </span>
                      </Tooltip>
                    )}
                  </CopyToClipboard>
                </Box>
                <IconButton
                  color="error"
                  aria-label="Delete ssh key"
                  data-testid="trash-icon-btn"
                  onClick={onDelete(key)}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </Box>
          );
        })
      ) : (
        <Typography sx={{ p: 3, pl: 2, pr: 2, display: "inline-block", color: "grey.600" }}>
          There are no SSH keys with access to your account.
        </Typography>
      )}
    </Box>
  );
}
