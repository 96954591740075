import { CustomButton } from "@control-tower/aerq-ui-library";
import { Typography } from "@mui/material";
import { WarningIcon } from "components/icons";
import { useNavigate } from "react-router-dom";
import { ErrorContainer, ErrorContent, ErrorIcon } from "theme/elements";

const ErrorPage = ({ message }: { message: string | undefined }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <ErrorContainer data-testid="error-container">
      <ErrorContent data-testid="error-content">
        <ErrorIcon>
          <WarningIcon />
        </ErrorIcon>
        <Typography variant="h1" align="center" mb={2}>
          Something went wrong :(
        </Typography>
        <Typography align="center" mb={2}>
          {message ? message : "There was a problem processing the request. Please try again!"}
        </Typography>
        <CustomButton onClick={handleClick} variant="outlined">
          Back To Prevous Page
        </CustomButton>
      </ErrorContent>
    </ErrorContainer>
  );
};

export default ErrorPage;
