import React from "react";
import Box from "@mui/material/Box";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { ContentHeaderWrapper } from "theme/elements";
import { Searchbar } from "../inputs";
import { GridViewIcon, ListViewIcon, SortAscIcon, SortDescIcon } from "../icons";
interface PropsType {
  view?: string;
  onViewChange: (view: string) => void;
  onSearch: (value: string) => void;
  searchText: string;
  sortBy: boolean;
  onSortBy: (value: boolean) => void;
}

export function ContentHeader({ onViewChange, view, onSearch, searchText, sortBy, onSortBy }: PropsType) {
  return (
    <ContentHeaderWrapper data-testid="page-control-header">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomButton
          variant="outlined"
          onClick={() => {
            onSortBy(!sortBy);
          }}
          endIcon={sortBy === true ? <SortDescIcon /> : <SortAscIcon />}
        >
          Sort By
        </CustomButton>
        <Searchbar onSearch={onSearch} searchText={searchText} />
      </Box>
      <Box>
        <CustomButton
          data-testid="grid-view-icon"
          className={view === "grid" ? "active" : ""}
          onClick={() => onViewChange("grid")}
          variant="icon"
          sx={view === "grid" ? { "& path": { stroke: "#424242" } } : { "& path": { stroke: "#BDBDBD" } }}
        >
          <GridViewIcon />
        </CustomButton>
        <CustomButton
          onClick={() => onViewChange("list")}
          variant="icon"
          className={view === "list" ? "active" : ""}
          data-testid="list-view-icon"
          sx={view === "list" ? { "& path": { stroke: "#424242" } } : { "& path": { stroke: "#BDBDBD" } }}
        >
          <ListViewIcon />
        </CustomButton>
      </Box>
    </ContentHeaderWrapper>
  );
}
