export default {
  instance: {
    list: "/instances",
    instance: "/instances",
    create: "/instances",
    delete: "/instances",
    acmList: "/acm",
    acmInstance: "/acm",
    deleteAcm: "/acm",
  },
  settings: {
    serviceCatalog: "/services/catalog",
    settings: "/settings",
  },
};
