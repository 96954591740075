import React from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Header as NavigationHeader } from "@control-tower/aerq-navigation-library";
import { ENV_LABEL } from "constant";

export function Header() {
  const location = useLocation();
  const navigateTo = useNavigate();

  return <NavigationHeader Link={Link} navigateTo={navigateTo} logo={null} location={location} envLabel={ENV_LABEL} />;
}
